<template>
  <div class="referrals">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input id="search" type="text" name="" placeholder="Nome, Utente CVing" @input="filterSearch($event)">
      </div>

      <div class="date-box">
        <label for="date-from">Data inizio</label>
        <input id="date-from" type="date" @input="dateFromChanged($event)">
      </div>

      <div class="date-box">
        <label for="date-to">Data fine</label>
        <input id="date-to" type="date" @input="dateToChanged($event)">
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th v-for="(header) of headers" :key="header" class="orderable" @click="changeOrder(header.name, header.k)">
              <div class="">
                <span>{{ header.name }}</span>
                <font-awesome-icon v-if="filters.order.field == header" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="log in getFilteredRows()" :key="log.id">
            <td><span>{{ dayjs.utc(log.date, 'YYYY-MM-DD').local().format('DD/MM/YYYY') }}</span></td>
            <td><span>{{ log.company }}</span></td>
            <td style="text-align:right;padding-right:8%">
              <span>{{ log.amount }} €</span>
            </td>
            <td><span>{{ log.type }}</span></td>
            <td><span>{{ log.by }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import 'moment/locale/it'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import dayjs from 'dayjs'

export default {
  setup() {
    let logs = reactive([])
    const filters = reactive({
      order: {
        field: 'Data',
        asc: false,
        k: 'date'
      },
      search: [],
      dateFrom: null,
      dateTo: null
    })
    const headers = [{ name: 'Data', k: 'date' }, { name: 'Azienda', k:'company' }, { name:'Importo', k:'amount' }, { name:'Tipologia', k:'type' }, { name:'Inviato da', k:'by' }]
    const spinner = spinnerStore()
    const navbar = navbarStore()

    /**
		 * Set the order condition for the table
		 * @param {number} orderBy - the column to order by
		 */
    const changeOrder = (orderBy, k) => {
      if (filters.order.field == orderBy) {
        filters.order.asc = !filters.order.asc
      } else {
        filters.order.field = orderBy
        filters.order.k = k
        filters.order.asc = true
      }
    }

    /**
		 * manage the search input
		 * @param {Event} event - the oninput input event
		 */
    const filterSearch = ({ target }) => {
      filters.search = target.value ? target.value.split(' ') : []
    }

    const getFilteredRows = () => {
      const rows = logs
        .filter((f) => {
          // if the filter is empty each row is allowed
          if (!filters.search.length) return true

          let allFiltersArePresentInRow = true

          for (const filter of filters.search) {
            //check company
            let c = f.company.toLowerCase().includes(filter.toLowerCase())
            //check by
            let b = f.by.toString().toLowerCase().includes(filter.toLowerCase())
            //amount
            let a = f.amount.toString().toLowerCase().includes(filter.toLowerCase())
            //type
            let t = f.type.toString().toLowerCase().includes(filter.toLowerCase())

            allFiltersArePresentInRow = allFiltersArePresentInRow && (c || b || a || t)

            if (!allFiltersArePresentInRow) break
          }

          return allFiltersArePresentInRow
        })
        .filter(({ date }) => (!filters.dateFrom ? true : date >= filters.dateFrom))
        .filter(({ date }) => (!filters.dateTo ? true : date <= filters.dateTo))
        .sort(orderByColumnValues)

      return rows
    }

    const orderByColumnValues = (a, b) => {
      //todo implementare asc desc +true +false ?
      switch (filters.order.field) {
        
        case headers[0].name: // date
          if (dayjs(a.date, 'YYYY-MM-DD') < dayjs(b.date, 'YYYY-MM-DD')) return filters.order.asc ? -1 : 1
          if (dayjs(a.date, 'YYYY-MM-DD') > dayjs(b.date, 'YYYY-MM-DD')) return filters.order.asc ? 1 : -1
          break
        
        case headers[2].name: // amount
          if (Number(a.amount) < Number(b.amount)) return filters.order.asc ? -1 : 1
          if (Number(a.amount) > Number(b.amount)) return filters.order.asc ? 1 : -1
          break

        case headers[1].name: // company
        case headers[3].name: // type
        case headers[4].name: // approved by
          if (a[filters.order.k].toLowerCase() < b[filters.order.k].toLowerCase()) return filters.order.asc ? -1 : 1
          if (a[filters.order.k].toLowerCase() > b[filters.order.k].toLowerCase()) return filters.order.asc ? 1 : -1
          break
        default:
          return 0
      }
      return 0
    }

    onMounted(async () => {
      navbar.title = 'Storico'
      
      spinner.show()
      /*       sbapibackoffice.get('/user/actions')
        .then(({ data }) => {
          logs.push(...data)
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => {
          spinner.hide()
        }) */
      Object.assign(logs, [
        {
          id: 1,
          date: '2022-10-02',
          company: 'A Company',
          amount: '190',
          type: 'Bank trasfer',
          by: 'Marina Blu'
        },
        {
          id: 2,
          date: '2022-09-02',
          company: 'B Company',
          amount: '100',
          type: 'Voucher Gift',
          by: 'Marta Rossi'
        },
        {
          id: 3,
          date: '2022-04-02',
          company: 'C Company',
          amount: '12',
          type: 'Bank trasfer',
          by: 'Elena Ganci'
        },
        {
          id: 4,
          date: '2022-09-01',
          company: 'D Company',
          amount: '120',
          type: 'Voucher Gift',
          by: 'Elena Ganci'
        }
      ])

      spinner.hide()
    })

    return {
      logs,
      filters,
      headers,
      spinner,
      navbar,
      changeOrder,
      filterSearch,
      getFilteredRows,
      dayjs
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../assets/scss/referrals';
@use '../assets/scss/table';
.referrals table thead,
.referrals table thead tr:first-child,
.referrals table thead tr:first-child th{
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.referrals table tr th:first-child,
.referrals table .table-body tr td:first-child{
  padding-left:1.5rem!important;
}
.text-xs{
  font-size:13px;
  margin-top:4px;
}

table {
  th {
    &:nth-child(1) {
      width: 100px;
    }
  }
}
</style>
